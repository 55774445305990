import {
  createPlugin,
  createComponentExtension
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const herokuIdpCatalogPlugin = createPlugin({
  id: 'heroku-idp-catalog',
  routes: {
    root: rootRouteRef,
  },
});

export const HerokuIdpCatalogCard = herokuIdpCatalogPlugin.provide(
  createComponentExtension({
    name: 'HerokuIdpCatalogCard',
    component: {
      lazy: () =>
        import('./components/HerokuIdpCatalogCard').then(
          m => m.HerokuIdpCatalogCard,
        ),
    },
  }))