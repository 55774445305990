import {
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { HoneycombApiClient, honeycombApiRef } from './api';

export const honeycombDashboardPlugin = createPlugin({
  id: 'honeycomb-dashboard',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: honeycombApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new HoneycombApiClient(discoveryApi),
    }),
  ],
});

export const HoneycombDashboardPage = honeycombDashboardPlugin.provide(
  createRoutableExtension({
    name: 'HoneycombDashboardPage',
    component: () =>
      import('./components/HoneycombDashboard').then(m => m.default),
    mountPoint: rootRouteRef,
  }),
);
