import { createApiRef } from '@backstage/core-plugin-api';

export interface RequestQuery {
  status?: string;
  target_name?: string;
  interval: string;
  component?: string;
}

export interface ResultLeadTimeQuery {
  period: string;
  avg_lead_time_hours: string;
  count: string;
  status: string;
  target_name: string;
  component: string;
}

export interface ResultDeploymentFrequencyQuery {
  period: string;
  count: string;
  status: string;
  target_name: string;
  component: string;
}

export interface DoraMetricsApi {
  deploymentFrequency: (
    data: RequestQuery,
  ) => Promise<ResultDeploymentFrequencyQuery[]>;
  leadTime: (data: RequestQuery) => Promise<ResultLeadTimeQuery[]>;
}

export const doraMetricsApiRef = createApiRef<DoraMetricsApi>({
  id: 'plugin.dora-metrics.service',
});

import { DiscoveryApi } from '@backstage/core-plugin-api';

export class DoraMetricsClient implements DoraMetricsApi {
  discoveryApi: DiscoveryApi;

  constructor(discoveryApi: DiscoveryApi) {
    this.discoveryApi = discoveryApi;
  }

  async leadTime(query: RequestQuery): Promise<ResultLeadTimeQuery[]> {
    const url = await this.doraMetricsUrl();
    this.cleanEmpty(query as any);
    const { data } = await this.fetch<any>(`/lead_time`, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });
    return data.map((item: ResultLeadTimeQuery) => ({
      period: new Date(item.period).toDateString(),
      avg_lead_time_hours: item.avg_lead_time_hours,
      count: item.count,
      status: item.status,
      target_name: item.target_name,
      component: item.component,
    }));
  }

  async deploymentFrequency(
    query: RequestQuery,
  ): Promise<ResultDeploymentFrequencyQuery[]> {
    const url = await this.doraMetricsUrl();
    this.cleanEmpty(query as any);
    const { data } = await this.fetch<any>(`/deployment_frequency`, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(query),
    });

    return data.map((item: ResultLeadTimeQuery) => ({
      period: new Date(item.period).toDateString(),
      avg_lead_time_hours: item.avg_lead_time_hours,
      count: item.count,
      status: item.status,
      target_name: item.target_name,
      component: item.component,
    }));
  }

  private async doraMetricsUrl(): Promise<string> {
    return `${await this.discoveryApi.getBaseUrl('dora-metrics')}`;
  }

  private cleanEmpty(obj: { [x: string]: string }) {
    Object.keys(obj).forEach(key => {
      if (obj[key] === 'EMPTY') {
        obj[key] = '';
      }
    });
  }

  private async fetch<T = any>(
    input: string,
    url: string,
    init?: RequestInit,
  ): Promise<T> {
    const resp = await fetch(`${url}${input}`, init);
    if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText} ${url}`);
    return await resp.json();
  }
}
