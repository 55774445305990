import { discoveryApiRef, useApi } from '@backstage/core-plugin-api';
import { useLocation } from 'react-router-dom';

export const Analytics = ({ children }) => {
  const discoveryApi = useApi(discoveryApiRef);
  const location = useLocation();

  discoveryApi.getBaseUrl('analytics').then(value => {
    const url = `${value}/count`;
    const data = {
      name: 'backstage.pages.view',
      attributes: { action: location.pathname },
    };
    fetch(url, {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }).then(value => console.log(value));
  });

  return children;
};
