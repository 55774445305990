import { Content, Header, Page } from '@backstage/core-components';
import { useRouteRefParams } from '@backstage/core-plugin-api';
import React from 'react';
import { detailRouteRef } from '../../routes';
import { Assistant, assistantsByName } from '../../data';

export const AssistantDetailComponent = () => {
  const { assistantName }     = useRouteRefParams(detailRouteRef);
  const assistant: Assistant  = assistantsByName[assistantName];
  const AssistantComponent    = assistant.component;
  
  return (
    <Page themeId='tool'>
      <Header title={assistant.title}
              subtitle={assistant.description} />
      
      <Content>
        {
          AssistantComponent === undefined || AssistantComponent === null
          ? <div>Coming Soon</div>
          : <AssistantComponent />
        }
      </Content>
    </Page>
  );
};