import { herokuApiRef, HerokuApiClient } from './api';

import {
  createApiFactory,
  discoveryApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const herokuPlugin = createPlugin({
  id: 'heroku',
  routes: {
    root: rootRouteRef,
  },

  apis: [
    createApiFactory({
      api: herokuApiRef,
      deps: { discoveryApi: discoveryApiRef },
      factory: ({ discoveryApi }) => new HerokuApiClient({ discoveryApi }),
    }),
  ],
});

export const HerokuPage = herokuPlugin.provide(
  createRoutableExtension({
    name: 'HerokuPage',
    component: () => import('./components/Pipeline').then(m => m.Pipeline),
    mountPoint: rootRouteRef,
  }),
);
