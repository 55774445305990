// NOTE: For now, we're directly using SeatedUserContentComponent
// for Amazon Q.  We do this because using AwsQDetailsComponent
// requires IAM Hub.  Currently, IAM Hub is a work-in-progress.
// When IAM Hub is completed, we'll switch to using AwsQDetailsComponent.

// import { AwsQDetailsComponent } from "../components/AwsQDetailsComponent";
import SeatedUserContentComponent from "../components/AwsQDetailsComponent/SeatedUserContentComponent";
import { Assistant, AssistantIndex } from "./types";

export const assistants: Array<Assistant> = [
  {
    title: 'Amazon Q',
    description: 'Request or release your Amazon Q seat, or read installation instructions.',
    assistantName: 'amazon-q',
    component: SeatedUserContentComponent // AwsQDetailsComponent
  }, {
    title: 'Github Copilot',
    description: 'Coming soon.',
    assistantName: 'github-copilot'
  }, {
    title: 'Salesforce CodeGenie',
    description: 'Coming soon.',
    assistantName: 'salesforce-codegenie'
  }
];

const indexOnName = (index: AssistantIndex, assistant: Assistant) => {
  const { assistantName } = assistant;
  index[assistantName] = assistant;
  return index;
};

export const assistantsByName: AssistantIndex = assistants.reduce<AssistantIndex>(indexOnName, {});