import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { PagerDutyService } from '@pagerduty/backstage-plugin-common';
import { NotFoundError } from '@backstage/errors';
import {
  CustomPagerDutyTeam,
  Incident,
} from '@internal/backstage-plugin-dora-metrics-backend/src/api/pagerduty.api';

/** @public */
export interface CustomPagerDutyApi {
  getTeams: (tag: string) => Promise<CustomPagerDutyTeam[]>;
  getServices: (team: string) => Promise<PagerDutyService[]>;
  getIncidents: (service: string) => Promise<Incident[]>;
}

/** @public */
export class UnauthorizedError extends Error {}

/** @public */
export class ForbiddenError extends Error {}

/** @public */
export type PagerDutyClientApiDependencies = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

/** @public */
export type PagerDutyClientApiConfig = PagerDutyClientApiDependencies & {
  eventsBaseUrl?: string;
};

export type RequestOptions = {
  method: string;
  headers: HeadersInit;
  body?: BodyInit;
};

export const customPDApiRef = createApiRef<CustomPagerDutyApi>({
  id: 'plugin.dora-metrics.pd.service',
});

/** @public */
export class CustomPagerDutyClient implements CustomPagerDutyApi {
  constructor(private readonly config: PagerDutyClientApiConfig) {}

  static fromConfig(
    configApi: ConfigApi,
    dependencies: PagerDutyClientApiDependencies,
  ) {
    const { discoveryApi, fetchApi } = dependencies;

    const eventsBaseUrl: string =
      configApi.getOptionalString('pagerDuty.eventsBaseUrl') ??
      'https://events.pagerduty.com/v2';

    return new CustomPagerDutyClient({
      eventsBaseUrl,
      discoveryApi,
      fetchApi,
    });
  }

  async getTeams(query: string): Promise<CustomPagerDutyTeam[]> {
    let url = `${await this.config.discoveryApi.getBaseUrl(
      'dora-metrics',
    )}/pagerduty/teams?query=${query}`;

    return await this.findByUrl<CustomPagerDutyTeam[]>(url);
  }

  async getServices(team: string): Promise<PagerDutyService[]> {
    let url = `${await this.config.discoveryApi.getBaseUrl(
      'dora-metrics',
    )}/pagerduty/services?query=${team}`;

    return await this.findByUrl<PagerDutyService[]>(url);
  }

  async getIncidents(service: string): Promise<Incident[]> {
    let url = `${await this.config.discoveryApi.getBaseUrl(
      'dora-metrics',
    )}/pagerduty/incidents?query=${service}`;
    return await this.findByUrl<Incident[]>(url);
  }

  private async findByUrl<T>(url: string): Promise<T> {
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/vnd.pagerduty+json;version=2',
        'Content-Type': 'application/json',
      },
    };
    const response = await this.request(url, options);
    return await response.json();
  }

  private async request(
    url: string,
    options: RequestOptions,
  ): Promise<Response> {
    const response = await this.config.fetchApi.fetch(url, options);
    if (response.status === 401) {
      throw new UnauthorizedError(
        "Unauthorized: You don't have access to this resource",
      );
    }

    if (response.status === 403) {
      throw new ForbiddenError(
        'Forbidden: You are not allowed to perform this action',
      );
    }

    if (response.status === 404) {
      throw new NotFoundError('Not Found: Resource not found');
    }

    if (!response.ok) {
      const payload = await response.json();
      const errors = payload.errors.map((error: string) => error).join(' ');
      const message = `Request failed with ${response.status}, ${errors}`;
      throw new Error(message);
    }
    return response;
  }
}
