import { createApiRef, DiscoveryApi } from '@backstage/core-plugin-api';

export interface HerokuApp {
  acm: boolean;
  archived_at: string;
  buildpack_provided_description: string;
  build_stack: {
    id: string;
    name: string;
  };
  created_at: string;
  git_url: string;
  id: string;
  internal_routing: boolean;
  maintenance: boolean;
  name: string;
  owner: {
    email: string;
    id: string;
  };
  organization: {
    id: string;
    name: string;
  };
  team: {
    id: string;
    name: string;
  };
  region: {
    id: string;
    name: string;
  };
  released_at: string;
  repo_size: number;
  slug_size: number;
  space: {
    id: string;
    name: string;
    shield: boolean;
  };
  stack: {
    id: string;
    name: string;
  };
  updated_at: string;
  web_url: string;
}

interface HerokuPipeline {
  created_at: string;
  id: string;
  name: string;
  owner: {
    id: string;
    type: string;
  };
  updated_at: string;
}

export interface HerokuPipelineCoupling {
  app: {
    id: string;
  };
  created_at: string;
  id: string;
  pipeline: {
    id: string;
  };
  stage: string;
  updated_at: string;
}

export interface HerokuApi {
  listPipelineCouplings: (
    pipelineId: string,
  ) => Promise<List<PipelineCoupling>>;
  getApps: (pipelineId: string) => Promise<HerokuApp[]>;
}

export const herokuApiRef = createApiRef<HerokuApi>({
  id: 'plugin.heroku.service',
});

export class HerokuApiClient implements HerokuApi {
  discoveryApi: DiscoveryApi;

  constructor({ discoveryApi }: { discoveryApi: DiscoveryApi }) {
    this.discoveryApi = discoveryApi;
  }

  async getApp(appId: string): Promise<List<HerokuApp>> {
    return await this.fetch<HerokuApp[]>(`/apps/${appId}`);
  }

  fetchMockData(data: any): Promise<any> {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 300);
    });
  }

  async getApps(pipelineId: string): Promise<HerokuApp[]> {
    const pipelinesCouplings = await this.listPipelineCouplings(pipelineId);
    const appPromises = pipelinesCouplings.map(
      async (pc: HerokuPipelineCoupling) => {
        return await this.getApp(pc.app.id);
      },
    );
    return await Promise.all(appPromises);
  }

  async getPipeline(pipelineId: string): Promise<List<HerokuPipeline>> {
    return await this.fetch<List<HerokuPipeline>>(`/pipelines/${pipelineId}`);
  }

  async listPipelineCouplings(
    pipelineId: string,
  ): Promise<List<HerokuPipelineCoupling>> {
    return await this.fetch<List<HerokuPipelineCoupling>>(
      `/pipelines/${pipelineId}/pipeline-couplings`,
    );
  }

  private async fetch<T = any>(input: string, init?: RequestInit): Promise<T> {
    const proxyUri = `${await this.discoveryApi.getBaseUrl(
      'proxy',
    )}/heroku-api`;
    const resp = await fetch(`${proxyUri}${input}`, init);
    if (!resp.ok) throw new Error(`${resp.status} ${resp.statusText} ${input}`);
    return await resp.json();
  }
}
